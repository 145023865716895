<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">mdi-view-quilt</v-icon>

      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light">
      <span @click="home" class="d-flex mt-1" style="cursor: pointer">Happy Tickets<v-img width="40" href="/" class="mt-n1 ml-2" src="/icon-large.png"/></span>
    </v-toolbar-title>

    <v-spacer />

    <v-tooltip bottom v-if="currentBoxOffice.id">
      <template v-slot:activator="{ on }">
        <v-btn class="ml-2" min-width="0" v-on="on" text :to="'/dashboard/Events/' + currentBoxOffice.id">
          <v-icon>mdi-calendar-month</v-icon>
        </v-btn>
      </template>
      Switch Event
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="ml-2" min-width="0" v-on="on" text to="/dashboard/BoxOffices">
          <v-icon>mdi-ticket-account</v-icon>
        </v-btn>
      </template>
      Switch Box Office
    </v-tooltip>
 
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <app-bar-item to="/Dashboard/Profile">
          <v-list-item-title>Profile</v-list-item-title>
        </app-bar-item>
        <app-bar-item>
          <v-list-item-title @click="logout()">Logout</v-list-item-title>
        </app-bar-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { VHover, VListItem } from 'vuetify/lib'
  import { mapState, mapMutations } from 'vuex'
  import Store from '@/services/Store.js';
  import Utils from '@/services/Utils.js';

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      currentBoxOffice: Store.boxOffice,
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      logout() {
        Utils.logout(this, location.pathname)
      },

      home() {
        window.location = "/";
      },

    },

  }
</script>
